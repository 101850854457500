import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue8({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Contracting, tax return, eco website metrics and more"
            pageSocialImage=""
            pageTitle="Issue #8"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #8"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "It’s been a few months since the last issue but I’m back and hope to make it a regular thing again.", type: "paragraph"},
                        {spans: [], text: "Sometimes it’s difficult to keep something like this going when you have so much else going on but I also find it helpful to reflect on how things are going.", type: "paragraph"},
                        {spans: [], text: "So let’s get back into it.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at October"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "Work has been pretty busy since the last issue (well it’s been busy since I started). But I’ve somewhat unknowingly moved into more contract work recently.", type: "paragraph"},
                        {spans: [], text: "For the past ~4 months I’ve been doing contract work on a part-time basis for 2 different companies. The first one finished at the end of August (they wanted to extend and even offered me a full-time role but I already had other stuff lined up), this contract was for 3 months at 3 days a week.", type: "paragraph"},
                        {spans: [], text: "And the new contract I’ve started is for a local agency, again 3 days a week until Christmas (having started on 20th Sep). But they’ve already offered to extend it post Christmas and have asked about hiring me multiple times now.", type: "paragraph"},
                        {spans: [], text: "Why does any of this matter? Well as a freelancer, there is no rule book to say who you have to work with and how. You can pick and choose what works best for you.", type: "paragraph"},
                        {spans: [], text: "The upside of contracting is you’re not having to juggle as many things and you have a clear idea of how much you’ll earn over that period. The downside is, you have a ceiling on what you can earn as you’ll be pricing at a day rate.", type: "paragraph"},
                        {spans: [], text: "Since I started freelancing I’ve been using a combination of fixed price and day rate pricing. Having this mix has worked extremely well for me. Day rate can be an easier foot in the door and not all projects lend themselves to fixed price (like contract work).", type: "paragraph"},
                        {spans: [], text: "💸 Tax Scouts", type: "heading4"},
                        {spans: [], text: "It’s getting to that time of year where it’s time to start thinking about my tax return. Prior to this, I’ve only done freelance work part-time so I always did the tax return myself.", type: "paragraph"},
                        {spans: [], text: "This time around I’ve decided to try out TaxScouts and see what that experience is like and if they’re able to save me any money.", type: "paragraph"},
                        {spans: [], text: "When I started freelancing I took the approach of taking 30% from each payment and putting it directly into another pot – I use Monzo business so any payment I receive, 30% automatically get’s put into a tax pot.", type: "paragraph"},
                        {spans: [], text: "I recently increased this to 40% though, based on my predictions, for the next tax return I’m going to need more than 30%.", type: "paragraph"},
                        {spans: [], text: "🌱 EcoPing", type: "heading4"},
                        {spans: [], text: "Since my last issue, I finally managed to launch my new business site – still some work to do but it’s definitely more refined than my old one.", type: "paragraph"},
                        {spans: [], text: "As part of that launch, I also came across EcoPing, a tool that lets you track the eco score for your site. So I hooked it up to my homepage and I’m currently getting a score of 91/100 which is pretty good going.", type: "paragraph"},
                        {spans: [], text: "✍️ Notion setup", type: "heading4"},
                        {spans: [], text: "I’ve been using Notion for a good amount of time now at least 2 years. I also use it for most of my freelance stuff too. When I started freelancing I created a really basic Notion setup to manage stuff, but I took some time out to create a new setup that is a little bit smarter.", type: "paragraph"},
                        {spans: [], text: "For my freelance work, I use Notion for keeping track of my schedule, what invoices I need to send out and which invoices I’m waiting to be paid. I also use it for individual clients and things related to them.", type: "paragraph"},
                        {spans: [], text: "At some point, I’ll do a more detailed post on my setup and what I use it for.", type: "paragraph"},
                        {spans: [], text: "💻 Personal website", type: "heading4"},
                        {spans: [], text: "Now that my business site is in a good place, I spent some time refining the designs for my personal website.", type: "paragraph"},
                        {spans: [], text: "My personal site will be a place for me to document my experiences – a home to everything that I hope to do. It will likely contain posts that are detailed and researched and then others that are off the cuff.", type: "paragraph"},
                        {spans: [], text: "To begin with, I’ll have 2 overarching areas – freelancing and health. Part of which will include my favourite products and apps that I use along with blog posts relevant to each topic.", type: "paragraph"},
                        {spans: [], text: "If all goes well, I’m hoping to have the first version of this live next month.", type: "paragraph"},
                        {spans: [], text: "⏲ 4-day “work” week", type: "heading4"},
                        {spans: [], text: "Something I’m working towards is a 4-day ”work” week. When I say this, I mean 4 days of client work and reserve Fridays for personal projects.", type: "paragraph"},
                        {spans: [], text: "I sometimes wonder, if I didn’t have to worry about money, what would I do with my time.", type: "paragraph"},
                        {spans: [], text: "One thing I would do is work on personal projects, so it only makes sense to me, to try and carve time out of my week to do the things I enjoy.", type: "paragraph"},
                        {spans: [], text: "My goal is to try and introduce this 4 day work week in the new year. But this means I’ll have to slightly re-think how I take on client work, as I typically have 1 main client for 3 days a week and fill in the other 2 with bits and pieces from existing relationships.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 October goals"
                    checkList={[
                        {complete: true, text: "🗞 Startup monthly newsletter again"},
                        {complete: true, text: "💻 Build components for my personal site"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to November"
                    content={[
                        {spans: [], text: "Work-wise, I don’t expect thing’s to be too dissimilar to October. 3 days a week will be taken up by my contract project where I’m busy working on a new website for a honey company (you might have some of their honey in your cupboard).", type: "paragraph"},
                        {spans: [], text: "Other than this, I have a new project in the pipeline which if all pans out will use up the rest of my available time for Nov/Dec.", type: "paragraph"},
                        {spans: [], text: "If this doesn’t pan out, I’ll likely fill some of the days with existing clients and then use the other days to work on personal projects.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 November goals"
                    checkList={[
                        {complete: false, text: "💸 Submit Tax Scouts"},
                        {complete: false, text: "🚀 Launch personal site"},
                        {complete: false, text: "✍️ Write a blog post"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Don’t be afraid to use different pricing methods for different projects, I use a combination of fixed price and day rate pricing depending on the project."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/0b486c06-e8ba-4ead-a329-99386cc90758_oct-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £5k (also had a week off on holiday)", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/18ca05ea-686a-460a-869d-a7ffb0832984_chilly-coffee-cup.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Chilly coffee cup", type: "heading4"},
                        {spans: [], text: "After going on holiday and now commuting to an office 3 days a week, I really needed a re-usable coffee cup so I can take coffee with me and not use single-use cups. Naturally, I decided to get the cup from Chilly which matches my water bottle. There’s a couple of downfalls to it but I’d definitely buy it again if I didn’t have it already.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.chillys.com/uk/products/coffee-cup-series-2-granite?sku=C340S2GGRY&collar=A_COFHEA_S2GGRY&lid=A_COFLID_S2GGRY",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/e6a405fd-1dc1-41ac-8ebb-7a5112e65b23_book-how-to-read-a-book.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "How to read a book", type: "heading4"},
                        {spans: [], text: "I recently bought this book to see if I could improve my underlying reading skills – so I can get the most out of other books I read. I’ve only made it through the beginning of the book, the times I have tried to read it has felt very difficult. I’m not sure if it’s written in a way that is difficult to understand or if I’ve been slightly distracted when trying.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/How-Read-Book-Intelligent-Touchstone/dp/0671212095/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue8;
